/*!
 * Header, Footer, Navivation Bar, etc ::: for JoshuaProject - imported by '../main.less' stylesheet 
 */

// Imporing Variables & Mixins
@import '../variables.less';
@import "~bootstrap/less/mixins.less";


/* Navigation Bar */
.navbar {
	background-color: @charcoal;
 	font-family: @brand-font-family;
 	font-weight:900;
 	font-size:120%;
    z-index: 1000;
    margin-bottom: 0px;

	img.jp-logo {
		display: inline-block;
		margin-right: 0.5rem;
		height: 40px;
		width: 40px;
		@media (max-width: @screen-sm) {
			height: 35px;
			width: 35px;
		}
		/* margin-top: -5px; */
	}

	.navbar-header {
		& > a.navbar-brand {
			display: inline-block;
			font-size: 24px;
			padding: 2px 15px;
			/* margin-top: 9px; */
			margin-bottom: 6px;
			@media (max-width: @screen-sm) {
				font-size: 20px;
				padding: 8px 15px 12px 10px;
			}
		}
		.navbar-toggle {
			border: None;
			margin-top: 16px;
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

 	li {
 		text-transform: uppercase;

 		a {
			color: @white1;

 			&:hover {
 				text-decoration: none;
 			}
 		}

 	}
}

#main-nav-wrapper {
	max-width: 1170px;
	margin: auto;
}

#jsp-main-nav {
    
    .navbar-nav {
        margin-right: 10px;

        & > li > a {
            color: #ffffff;
            font-size: 15px;
            padding-top: 24px;
            padding-bottom: 22px;
            @media (max-width: 1024px) {
				padding-left: 10px !important;
				padding-right: 10px !important;
			}

            @media (max-width: @screen-sm) {
				padding-top: 17px;
				padding-bottom: 17px;
			}

            &:hover, &:visited, &:focus {
                background-color: @charcoal-light;
                color: #ffffff;
                text-decoration: none;
                opacity: 0.8;
            }
        }

		.glyphicon-plus, .glyphicon-remove {
			display: none;
		}
    }
	.yamm-fw .dropdown-menu {
		left: 0;
		right: 0;
	}

    .dropdown-menu {
        font-size: 15px;
		left: auto;
		right: auto;
		max-width: 1170px;
		margin: auto;

        & > li > a {
            color: @dropdown-link-color;
            background-color: transparent;
            padding: 5px 20px;

            &:hover, &:focus, &:active {
                background-color: transparent;
                color: @dropdown-link-hover-color;
                text-decoration: none;
            }
        }
    }
	.tooltip {
		background-color: black;
		.tooltip-inner {
			background-color: black;
			text-align: center;
		}
	}
	.tooltip.bottom .tooltip-arrow {
		border-bottom-color: black;
		top: -5px;
	}
}
.yamm .yamm-content {
	padding: 10px 15px 10px 15px;
	
	.pray-two-col {
		display: inline-block; 
		padding-right:15px;
		vertical-align: top;
	}
    ul > li > a {
        display: block;
        padding: 5px 0px;
		text-transform: none;

        &.active {
            color: @dropdown-link-hover-color;
        }
    }
}

.mega-menu {
	.wrapped-well {
		padding: 0px;
	}

	.promo-well {
		background-color: #f5f5f5;
		padding: 15px;

		h3 {
			font-size: 100%;
		}

		p {
			font-size: 85%;
			text-transform: none;
		}
	}
    p.section-header {
        color: @black;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 900;
		margin-bottom: .75%;

        a {
			padding: 31px 0px 20px;
			font-size: 15px !important;
			font-weight: 900;
            color: @black !important;
        }
        a:hover, a:focus, a:active {
        	font-size: 15px;
            background-color: transparent;
            opacity: 0.7;
            color: @black !important;
        }
    }

    hr {
    	margin: 0;
    }

    .dropdown-menu {
		padding-top: 0;
		margin-top: 0;

		li {
			/* margin-left: 15px; */
			width: 100%;
		}

		div {
			h3 {
				margin: 8px 0px;
	
				a {
					padding: 5px 5px;
					color: @gray-darker;
					font-size: @font-size-h3;

					&:hover, &:focus, &:active {
						color: @gray-dark;
						text-decoration: none;
					}
				}

			}
			.section-header a {
				padding: 0px;
				@media (max-width: @screen-sm) {
					padding: 10px 0px 5px 0px;
				}
			}

			a {
				background-color: transparent;
				color: @dropdown-link-color;
				font-size: 14px;
				
				&:hover, &focus, &:active {
					background-color: transparent;
					color: @dropdown-link-hover-color;

				}
			}
		}
	}
}
a.wrapped-link {
    display: block;
}
.hideonsm {
	@media (max-width: @screen-sm) {
		display:none;
	}
}


/* Footer */
.footer-container {
	width: 100%;
	max-width: 1170px;
	margin: auto;
	margin-top: 15px;
}

.footer-banner {
	background-color: @charcoal;
	color: @white1;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 145%;
	font-weight: 700;
	@media (max-width:@screen-sm) {
		font-size: 120%;
	}
	@media (max-width:@screen-xs) {
		font-size: 120%;
		height: 55px;
		align-items: center;
	}
}

.footer-links {
	line-height: 1.8em;
	a, a:visited {
		color: @black;
	}
}
.footer-logo {
	img {
		padding-top: 10px;
		padding-bottom: 15px;
	}		
}

.footer-brand {
	font-size: 1.3em;
	font-weight: bold;
	margin-left: 15px;
	a, a:visited {
		color: black;
		text-decoration: none;
	}
}

.email-signup {
	padding: 15px 0;
	margin: 0;
	border-top: 4px solid black;
	@media (max-width: @screen-sm) {
		padding: 0;
	}
	table {
		width: 95%;
		tr {
			width: 100%;
		}
		td {
			padding-top: 9px;
			white-space: nowrap;
		}
		td:first-child {
			text-align: right;
			padding-right: 10px;
			padding-left: 0px;
			vertical-align: middle;
		}
		select {
			height: 30px;
		}
		input {
			/* border-radius: 5px; */
			border: 1px solid black;
			width: 100%;
			height: 30px;
			@media (max-width: @screen-xs) {
				height: 30px;
			}
		}
	}
}

.email-signup-title {
	font-size: 125%;
	font-weight: bold;
	margin-bottom: 5px;
	color: @red2;
	@media (max-width: @screen-xs) {
		text-align: center;
		margin: auto;
		padding-top: 10px;
		width: 100%;
	}
}

.email-signup-notes {
	font-size: 82%;
	line-height: 1.5em;
	max-width: 85%;
	@media (max-width: @screen-sm) {
		max-width: 90%;
		font-size: 85%;
	}
}

.social-icons {
	img {
		max-width: 40px;
		margin: 10px 5px 10px 5px;
	}
	@media (max-width: @screen-sm) {
		margin-top: 10px;
		/* background-color: white; */
	}
}

.ft_small {
	display: block;
	font-size: 110%;
	font-weight: normal;
	text-align: center;
	margin: 10px 10px 15px 0px;
	@media (max-width: @screen-xs) {
		margin: 0px 5px;
		img {
			max-width: 40px;
			margin-bottom: 7px;
		}
	}
}

.ft_ministry {
	vertical-align: middle;
	font-size: 85%;
	margin-top: 10px;
	color: #FFF;
	text-transform: none;
	a {
		color: #FFF;
		text-transform: uppercase;
	}
}

/* Footers Large (non-fixed multiple using this CSS) */
#ft_large {
	font-size:100%;
	margin: 10px 0 0 0;

	a {
		padding: 2% 0;
		display:block;
		@media (max-width: @screen-sm) {
			min-height: 75px;
			padding: 0 10px;
		}
		text-align: center;
		text-decoration: none;
		.icon {
			display:block;
			color: @blue2;
			font-size:290%;
			@media (max-width: @screen-xs) {
				font-size:190%;
				margin-bottom: 15px;
			}
		}
		.glyphicon {
			display:block;
			color: @blue2;
			font-size: 290%;
			margin-bottom: 25px;					
			@media (max-width: @screen-xs) {
				font-size:190%;
				margin-bottom: 15px;
			}
		}
		.title {
			display:block;
			font-weight:bold;
			text-transform: uppercase;
			font-size:100%;
			color:@gray-darker;
			@media (max-width: @screen-xs) {
				font-size: 80%;
			}
		}

		.box-sizing(border-box);

		@media (max-width: @screen-lg) {
			&.ft_pray {
				border-left:none;
			}
			&.ft_pray {
				border-top:none;
			}
			&.ft_serve {
				border-top:1px solid @gray-light;
			}
			&.ft_credits {
				border-top:1px solid @gray-light;
			}

		}
		.transition(all .3s);

		&:hover {
			background-color: @gray-lighter; // @offwhite2
			.transition(all 0s);
		}
	}
}
li.search-first {
	display: none;
}
.navbar-nav>li>a {
	padding-top: 15px;
	padding-bottom: 15px;
}
@media (max-width: 1014px) {
	// Set up mobile look and feel
	.navbar-collapse {
		padding-right: 0;
		max-height: 500px !important;
	}
	#jsp-main-nav .navbar-nav {
		margin-right: 20px;

		li {
			display: block;
			
			@media (max-width: @screen-xs) {
				padding-left: 15px;
			}

			a[aria-expanded=true] {
				.glyphicon-plus {
					display: none;
				}
				.glyphicon-remove {
					display: block;
				}
			}
			a[aria-expanded=false] {
				.glyphicon-plus {
					display: block;
				}
				.glyphicon-remove {
					display: none;
				}
			}
		}
		.caret {
			display: none;
		}
		li.search-first, li.search-first a {
			display: block;
		}
		li.search-last {
			display: none;
		}
		.section-header {
			/* display: none; */
		}
		.yamm-content {
			background-color: @white1;
		}
		.dropdown-menu, .yamm-fw .dropdown-menu {
			float: none;
			left: auto;
			right: auto;

			.container {		
				width: 100%;

			}
		}
	}
}